const lPhantEmail = "contacto@lphant.io"
const lPhantTelephone = "81-12-53-15-00 Ext. 114 "
const lPhantEmailDisplay = "contacto@lphant.io "
const lPhantURL = "http://www.lphant.io/"
const lPhantURLDisplay = "www.lphant.io "
const lPhantSignUpURL = "http://app.lphant.io/registrarse/"
const lPhantSignUpURLDisplay = "http://app.lphant.io/registrarse "
const lPhantSignInURL = "http://app.lphant.io/iniciar-sesion/"
const lPhantSignInURLDisplay = "http://app.lphant.io/iniciar-sesion "
const lPhantFacebookURL = "https://www.facebook.com/LPhant.io"
const lPhantInstagramURL = "https://www.instagram.com/lphant.io/"
const lPhantWhatsappURL = "https://wa.me/message/55VK4NLIYRDSO1"
const lPhantPrivacyPolicyLink = "http://www.lphant.io/politica-de-privacidad/"
const lPhantPrivacyPolicyLinkDisplay = "Política de Privacidad "
const lPhantPasswordRecoveryURL =
  "http://app.lphant.io/recuperar-contrasena/"
const lPhantPasswordRecoveryURLDisplay =
  "http://app.lphant.io/recuperar-contrasena "
const lPhantCapMarketURL = "https://www.nomics.com/"
const lPhantCapMarketURLDisplay = "www.nomics.com "
const minMXN = "$1.000 "
const maxMXN = "$250.000 "

export {
  lPhantEmail,
  lPhantTelephone,
  lPhantEmailDisplay,
  lPhantURL,
  lPhantURLDisplay,
  lPhantSignUpURL,
  lPhantSignUpURLDisplay,
  lPhantSignInURL,
  lPhantSignInURLDisplay,
  lPhantFacebookURL,
  lPhantInstagramURL,
  lPhantWhatsappURL,
  lPhantPrivacyPolicyLink,
  lPhantPrivacyPolicyLinkDisplay,
  lPhantPasswordRecoveryURL,
  lPhantPasswordRecoveryURLDisplay,
  lPhantCapMarketURL,
  lPhantCapMarketURLDisplay,
  minMXN,
  maxMXN,
}
