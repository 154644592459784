import React from "react"

import { injectIntl } from "gatsby-plugin-intl"

import { Input } from "antd"
import { ArrowRightOutlined } from "@ant-design/icons"

import "./pre-footer-1.scss"

const PreFooter1 = ({ intl }) => (
  <div className="prefooter1">
    <div className="column">
      <h2>{intl.formatMessage({ id: "footer-message-title-1" })}</h2>
      <h4>{intl.formatMessage({ id: "footer-message-1" })}</h4>
    </div>
    <Input.Search
      placeholder={intl.formatMessage({
        id: "footer-subscribe-placeholder",
      })}
      allowClear
      enterButton={<ArrowRightOutlined className="ant-input-enter" />}
      size="large"
    />
  </div>
)

export default injectIntl(PreFooter1)
