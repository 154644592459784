import React from "react"

import { injectIntl, Link } from "gatsby-plugin-intl"

import Logo from "../../images/logo-all-white.svg"
import FacebookIcon from "../../images/icon-facebook-footer.svg"
import InstagramIcon from "../../images/icon-instagram-footer.svg"
import WhatsappIcon from "../../images/icon-whatsapp-footer.svg"
import {
  lPhantFacebookURL,
  lPhantInstagramURL,
  lPhantWhatsappURL,
  lPhantEmail,
  lPhantEmailDisplay,
} from "../../utils/variables"

import "./footer.scss"

const Footer = ({ intl }) => (
  <div className="footer">
    <div className="wrapper options ">
      <div className="col">
        <h4>{intl.formatMessage({ id: "footer-sections" })}</h4>
        <nav>
          <Link to="/">
            <span>{intl.formatMessage({ id: "nav-title-index" })}</span>
          </Link>
          <Link to="/calculadora/" state={{ menuSelector: 2 }}>
            <span>
              {intl.formatMessage({ id: "nav-title-loan-calculator-footer" })}
            </span>
          </Link>
          <Link to="/acerca-de-nosotros/">
            <span>
              {intl.formatMessage({ id: "nav-title-about-us-footer" })}
            </span>
          </Link>
          <Link to="/politica-de-privacidad/" state={{ menuSelector: 1 }}>
            <span>
              {intl.formatMessage({ id: "nav-title-privacy-policy-footer" })}
            </span>
          </Link>
          <Link to="/terminos-y-condiciones/" state={{ menuSelector: 1 }}>
            <span>
              {intl.formatMessage({ id: "nav-title-terms-conditions-footer" })}
            </span>
          </Link>
          <Link to="/faqs/">
            <span>{intl.formatMessage({ id: "nav-title-faqs-footer" })}</span>
          </Link>
        </nav>
      </div>
      <div className="col">
        <h4>{intl.formatMessage({ id: "footer-contact-us" })}</h4>
        <a href={`mailto: ${lPhantEmail}`}>
          <p>{lPhantEmailDisplay}</p>
        </a>
        <p>{`Teléfono: ${intl.formatMessage({ id: "footer-contact-us-phone" })}`}</p>
      </div>
    </div>
    <div className="wrapper mark-print content-wrapper">
      <div className="col">
        <div className="row social-networks">
          <a
            target="_blank"
            rel="noopener noreferrer external"
            href={lPhantFacebookURL}
          >
            <FacebookIcon />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer external"
            href={lPhantWhatsappURL}
          >
            <WhatsappIcon />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer external"
            href={lPhantInstagramURL}
          >
            <InstagramIcon />
          </a>
        </div>
      </div>
      <div className="col centered">
        <Logo />
      </div>
      <div className="col flex-end">
        <span>{intl.formatMessage({ id: "footer-copyright" })}</span>
      </div>
    </div>
  </div>
)

export default injectIntl(Footer)
