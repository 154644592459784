import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Button } from "antd"
import "./pre-footer-2.scss"

const PreFooter2 = ({ intl }) => (
  <div className="prefooter2">
    <div className="row">
      <h2>{intl.formatMessage({ id: "footer-message-title-2" })}</h2>
      <h4>{intl.formatMessage({ id: "footer-message-2" })}</h4>
    </div>
    <div className="row">
      <Link to="/faqs/">
        <Button href={process.env.ADMIN_URL} type="link">
          {intl.formatMessage({ id: "footer-FAQ" })}
        </Button>
      </Link>
    </div>
  </div>
)

export default injectIntl(PreFooter2)
