/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { notification, Button } from "antd"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header"
import PreFooter1 from "../pre-footer-1"
import PreFooter2 from "../pre-footer-2"
import Footer from "../footer"

import "./layout.scss"

const Layout = ({ children, prefooter, menuSelector }) => {
  const onClose = () => {
    localStorage.setItem("cookies-accepted", true)
    notification.close("cookies-disclaimer")
  }

  const btn = (
    <Button type="primary" size="small" onClick={onClose}>
      Aceptar
    </Button>
  )

  const cookiesDisclaimer = () => {
    if (!localStorage.getItem("cookies-accepted")) {
      notification.open({
        message: "Privacidad",
        description: (
          <span>
            Este sitio utiliza cookies para mejorar tu experiencia. Al continuar
            navegando, aceptas su uso. Haz click aquí para ver nuestra{" "}
            <a href="/#">política de privacidad</a>
          </span>
        ),
        btn,
        key: "cookies-disclaimer",
        duration: 0,
        placement: "bottomLeft",
        onClose,
      })
    }
  }

  useEffect(cookiesDisclaimer, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title}
        menuSelector={menuSelector}
      />
      <div>
        <main>{children}</main>
      </div>
      {prefooter === "1" && <PreFooter1 />}
      {prefooter === "2" && <PreFooter2 />}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
