import PropTypes from "prop-types"
import React from "react"

import { injectIntl, Link } from "gatsby-plugin-intl"

import Logo from "../../images/logo-white.svg"

import { Button } from "antd"

import "./header.scss"
import { lPhantSignUpURL, lPhantSignInURL } from "../../utils/variables"

const Header = ({ intl, menuSelector }) => (
  <div className="header">
    <div className="wrapper">
      <div className="logo-white">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <nav>
        <Link to="/calculadora/" state={{ menuSelector: 2 }}>
          <span className={menuSelector === 2 ? "primary-color" : ""}>
            {intl.formatMessage({ id: "nav-title-loan-calculator" })}
          </span>
        </Link>
        <Link to="/acerca-de-nosotros/" state={{ menuSelector: 3 }}>
          <span className={menuSelector === 3 ? "primary-color" : ""}>
            {intl.formatMessage({ id: "nav-title-about-us" })}
          </span>
        </Link>
        <Link to="/faqs/" state={{ menuSelector: 4 }}>
          <span className={menuSelector === 4 ? "primary-color" : ""}>
            {intl.formatMessage({ id: "nav-title-faqs" })}
          </span>
        </Link>
      </nav>
      <div className="stroke-46"></div>
      <div className="actions">
        <a
          target="_blank"
          rel="noopener noreferrer external"
          href={lPhantSignInURL}
        >
          {intl.formatMessage({ id: "header-login" })}
        </a>
        <Button
          target="_blank"
          rel="noopener noreferrer external"
          href={lPhantSignUpURL}
          type="primary"
        >
          {intl.formatMessage({ id: "header-sign-up" })}
        </Button>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)
